.auth {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: calc(100vh - 98px);
  width: 100%;

  &__container {
    box-sizing: border-box;
    margin: 0;
    max-width: 480px;
    padding: 0 15px;
    text-align: center;
    width: 100%;
  }

  &__form {
    margin: 30px 0 0;
    width: 100%;

    &__button {
      align-items: center;
      color: #222;
      display: flex;
      font-size: 16px;
      background: #fff;
      border: 2px solid #d4d4d4;
      border-radius: 16px;
      justify-content: center;
      margin: 14px auto 0;
      padding: 10px 40px;
      transition: all .25s ease;
      width: 100%;

      @media screen and (min-width: 521px) {
        width: 280px;
      }

      &:hover {
        color: #f75260;
        border: 2px solid #f75260;
        cursor: pointer;
      }

      span {
        font-weight: 600;
        padding-left: 10px;
      }
    }
  }

  &__txt {
    &__catch {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.025rem;
      line-height: 1.4;
      margin: 0;
      text-align: center;
    }

    &__offer {
      color: #999;
      font-size: 13px;
      line-height: 1.5;

      &_link {
        color: #222;
        font-weight: 600;
        transition: all .25s ease;

        &:hover {
          cursor: pointer;
          opacity: 0.65;
        }
      }
    }
  }
}