.bookmark {
  background: #fff;
  border: #ebebeb 1px solid;
  list-style: none;
  padding: 15px;

  @media screen and (min-width: 521px) {
    border-radius: 15px;
    padding: 25px 30px;
  }

  @media screen and (min-width: 961px) {
    padding: 25px 30px;
  }

  &:nth-child(n + 2) {
    margin-top: 15px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__savedAt {
      font-size: 15px;
      letter-spacing: 0.045rem;
      margin: 0;
    }

    &__user {
      display: flex;
      align-items: center;
      text-decoration: none;

      &_avatar {
        height: 35px;
        width: 35px;

        @media screen and (min-width: 521px) {
          height: auto;
          width: auto;
        }

        &:hover {
          -webkit-animation: zoomIcon .3s;
          animation: zoomIcon .3s;
        }
      }

      &_name {
        display: table;
        color: #4193ae;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.35;
        margin-left: 5px;

        @media screen and (min-width: 521px) {
          font-size: 18px;
        }

        &:hover {
          -webkit-animation: zoomName .3s;
          animation: zoomName .3s;
        }
      }
    }
  }

  &__body {
    margin-top: 10px;

    &_txt {
      color: #555;
      font-size: 14px;
      line-height: 1.65;
      letter-spacing: 0.015rem;
      margin: 6px 0 0;

      @media screen and (min-width: 521px) {
        font-size: 16px;
      }
    }

    &__meta {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;

      &_postedAt {
        color: #999;
        font-size: 14px;
        margin: 15px 0 0;
        width: 100%;

        @media screen and (min-width: 521px) {
          width: auto;
        }

        a {
          display: inline-block;
          color: #4193ae;
          letter-spacing: 0.015rem;
          text-decoration: none;
          &:hover {
            -webkit-animation: zoomPostedAt .3s;
            animation: zoomPostedAt .3s;
          }
        }
      }

      &__tags {
        display: flex;
        margin-top: 5px;
        width: 100%;

        @media screen and (min-width: 521px) {
          margin-left: 20px;
          margin-top: 0;
          width: auto;
        }

        &_head {
          color: #999;
          font-size: 14px;
          margin: 11px 0 0 0;
        }

        &_list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 0 0 0 10px;
          padding: 0;

          &__item {
            font-size: 13px;
            letter-spacing: 0.025rem;
            list-style: none;
            background: #d8eaf0;
            border-radius: 12px;
            margin-top: 5px;
            padding: 4px 6px;

            &:nth-child(n + 2) {
              margin-left: 6px;
            }
          }
        }

        &_iconButton {
          margin-left: 10px !important;
          &__icon {
            color: #787878;
            height: 18px !important;
            width: 18px !important;
          }
        }
      }
    }
  }
}

@-webkit-keyframes zoomName {
  50% {
    -webkit-transform: scale(1.0225);
  }
}
@keyframes zoomName {
  50% {
    transform: scale(1.0225);
  }
}

@-webkit-keyframes zoomIcon {
  50% {
    -webkit-transform: scale(1.085);
  }
}
@keyframes zoomIcon {
  50% {
    transform: scale(1.085);
  }
}

@-webkit-keyframes zoomPostedAt {
  50% {
    -webkit-transform: scale(1.085);
  }
}
@keyframes zoomPostedAt {
  50% {
    transform: scale(1.085);
  }
}

@-webkit-keyframes zoomTag {
  50% {
    -webkit-transform: scale(1.1);
  }
}
@keyframes zoomTag {
  50% {
    transform: scale(1.1);
  }
}