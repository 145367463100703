.global_drawer {
  &__inner {
    background-color: #fff;
    box-sizing: border-box;
    min-width: 300px;
    height: 100vh;
    padding: 20px 15px;
  }

  &__link {
    align-items: center;
    color: #222;
    display: flex;
    text-decoration: none;
  }

  &__txt {
    color: #222;
    margin: 0;
    padding: 5px;
  }

  &__txt_small {
    color: #999;
    font-size: 13px;
    margin: 0;
    padding: 0;
  }
}