.hashtag {
  &_list {
    padding: 0;
    overflow-y: scroll;
    width: 100%;

    @media screen and (min-width: 961px) {
      padding: 0 12px;
    }

    li {
      list-style: none;
    }

    &__search {
      background: #f4f4f4;
      padding: 10px 15px;
      width: 100%;

      @media screen and (min-width: 521px) {
        padding: 10px 30px;
      }

      @media screen and (min-width: 961px) {
        background: #fff;
        margin: 10px 0;
        padding: 0;
      }

      input {
        border: 0;
        border-radius: 8px;
        padding: 14px 10px;
        width: 100%;

        @media screen and (min-width: 961px) {
          background: #f6f6f6;
          border: 1px solid #f1f1f1;
        }

        &::placeholder {
          color: #999;
        }
      }
    }
  }

  &_item {
    &__link {
      color: #4193ae;
      border-bottom: 1px solid #f4f4f4;
      display: block;
      font-size: 14px;
      letter-spacing: 0.035rem;
      padding: 15px;
      text-decoration: none;
      transition: all .3s ease;
      width: 100%;

      @media screen and (min-width: 521px) {
        padding: 15px 30px;
      }

      @media screen and (min-width: 961px) {
        padding: 15px 0;
      }

      &_crr {
        color: #111;
        font-weight: 600;
        letter-spacing: 0.035rem;
        text-decoration: none;
        transition: all .3s ease;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.65;
        transform: translateX(-3px);
      }
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}