@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap');

.header {
  background: #f8f8f8;
  border-radius: 6px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  @media screen and (min-width: 961px) {
    background: transparent;
    top: 40px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 15px 0 25px;
    position: relative;
    width: 100%;

    @media screen and (min-width: 961px) {
      padding: 0 30px;
    }
  }

  &__col {
    &_l {
      width: 40%;

      h1 {
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 26px;
        letter-spacing: 0.035rem;
        margin: 0;

        @media screen and (min-width: 521px) {
          font-size: 26px;
        }

        span { color: #f75260; }
      }
    }

    &_r {
      width: auto;
    }
  }
}