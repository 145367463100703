.header {
  background: #f8f8f8;
  border-radius: 6px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  @media screen and (min-width: 961px) {
    top: 40px;
  }

  @media screen and (min-width: 961px) {
    background: transparent;
    width: calc(100% - 420px);
  }

  &__container {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px 0 10px;
    width: calc(100% - 30px);

    @media screen and (min-width: 521px) {
      padding: 30px 0 10px;
      width: calc(100% - 60px);
    }

    @media screen and (min-width: 961px) {
      padding: 0;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    width: 100%;

    @media screen and (min-width: 961px) {
      max-width: 960px;
    }
  }

  &__col {
    &_l {
      width: 40%;

      h1 {
        font-size: 26px;
        letter-spacing: -0.02rem;
        margin: 0;

        @media screen and (min-width: 521px) {
          font-size: 26px;
        }


        span { color: #f75260; }
      }
    }

    &_r {
      width: 60%;
    }
  }

  &__searchbox {
    display: none;

    @media screen and (min-width: 961px) {
      display: block;
    }
  }

  &__nav {
    @media screen and (min-width: 961px) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
    }
  }
}