.global_drawer {
  &__inner {
    background-color: #fff;
    box-sizing: border-box;
    min-width: 300px;
    height: 100vh;
    padding: 20px 15px;
  }

  &__userCard {
    background: rgb(80,80,80);
    background: linear-gradient(153deg, rgba(80,80,80,1) 0%, rgba(0,0,0,1) 100%);
    border-radius: 14px;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.2);
    margin: 0 auto 30px;
    padding: 24px 30px;
    width: 100%;

    &__user {
      display: flex;
      align-items: center;
    }

    &__name {
      color: #fff;
      font-weight: 600;
      padding-left: 14px;
      letter-spacing: 0.015rem;
      margin: 0;
    }
  }

  &__link {
    color: #222;
    text-decoration: none;
  }

  &__txt {
    color: #222;
    margin: 0;
    padding: 5px;
  }

  &__txt_small {
    color: #999;
    font-size: 13px;
    margin: 0;
    padding: 0;
  }
}