.feed {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;

  &__main {
    background: #f8f8f8;
    box-sizing: border-box;
    padding: 45px 0;
    position: relative;
    height: 100%;
    min-height: 100vh;
    width: 100%;

    @media screen and (min-width: 521px) {
      padding: 60px 30px 45px;
    }

    @media screen and (min-width: 961px) {
      padding: 45px 30px;
      width: calc(100% - 420px);
    }

    .bookmark__container {
      margin: 40px auto 0;
      max-width: 960px;

      @media screen and (min-width: 961px) {
        margin-top: 100px;
      }
    }
  }
}