.terms {
  background: #f8f8f8;
  width: 100%;

  &_inner {
    margin: 0 auto;
    padding-top: 50px;
    width: 100%;

    @media screen and (min-width: 961px) {
      padding-top: 100px;
      width: 926px;
    }
  }

  &_content {
    background: #fff;
    border: #ebebeb 1px solid;
    min-height: 100vh;
    padding: 40px 25px 60px;
    width: 100%;

    @media screen and (min-width: 521px) {
      border-radius: 15px;
      padding: 40px 38px 60px;
    }

    section {
      margin-bottom: 32px;
    }

    h2 {
      font-size: 28px;
      letter-spacing: 0.025rem;
      margin-bottom: 40px;

      @media screen and (min-width: 521px) {
        font-size: 32px;
      }
    }

    h2 + p {
      margin-bottom: 32px;
    }

    h3 {
      color: #222;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.025rem;
      line-height: 1.75;
      margin-bottom: 32px;
    }

    p {
      color: #555;
      font-size: 15px;
      letter-spacing: 0.025rem;
      line-height: 1.75;
    }
  }

  &_text_right {
    text-align: right;
  }
}