.search_drawer {
  background: #fff;
  border-radius: 12px 12px 0 0;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  padding: 0 0 15px;
  height: 100%;
  transition: all 0.4s;
  transform: translateY(100%);
  width: 100%;
  z-index: 10000;

  @media screen and (min-width: 961px) {
    padding: 30px 26px;
    right: 0;
    transform: none;
    width: 420px;
    z-index: 100;
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.4s;

    &:hover {
      cursor: pointer;
    }
  }

  &__userCard {
    display: none;
    background: rgb(80,80,80);
    background: linear-gradient(153deg, rgba(80,80,80,1) 0%, rgba(0,0,0,1) 100%);
    border-radius: 14px;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.2);
    margin: 0 auto;
    padding: 24px 30px;
    width: 100%;

    @media screen and (min-width: 961px) {
      display: block;
    }

    &__meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__user {
        display: flex;
        align-items: center;
      }

      &__name {
        color: #fff;
        font-weight: 600;
        padding-left: 14px;
        letter-spacing: 0.015rem;
        margin: 0;
      }

      &__iconButton {
        &__icon {
          color: #787878;
        }
      }
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      &__item {
        margin-top: 24px;
        text-align: center;

        &__title {
          color: #787878;
          font-size: 12px;
          font-weight: 500;
          margin: 0;
        }

        &__num {
          color: #fff;
          font-size: 30px;
          font-weight: 600;
          margin: 5px 0 0;
        }
      }
    }
  }

  &__link {
    color: #222;
    text-decoration: none;
  }

  &__tags {
    @media screen and (min-width: 961px) {
      margin-top: 60px;
    }

    &__title {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      padding: 15px 0;
      position: relative;

      @media screen and (min-width: 961px) {
        margin: 0 0 24px;
        text-align: left;
        padding: 0 12px;
      }
      &__icon {
        background: #d8eaf0;
        border-radius: 8px;
        padding: 4px 8px;
        margin-right: 10px;

        @media screen and (min-width: 961px) {
          border-radius: 12px;
          margin-right: 14px;
          padding: 16px 18px;
        }

        img {
          line-height: 0;
          width: 12px;

          @media screen and (min-width: 961px) {
            width: 14px;
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &_open {
    opacity: 1;
    height: 45vh;
    transform: translateY(0);
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.2);
    box-sizing: border-box;
    padding: 10px 15px;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;

    @media screen and (min-width: 961px) {
      display: none;
    }
  }

  &__btn {
    &_close {
      position: absolute !important;
      right: 5px !important;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (min-width: 521px) {
        right: 20px;
      }

      @media screen and (min-width: 961px) {
        display: none !important;
      }

      &_icon {
        height: 20px;
        width: 20px;
      }
    }

    &_filter {
      color: #fff;
      font-size: 14px;
      border-radius: 50px;
      box-sizing: border-box;
      background: #222;
      padding: 9px;
      transition: all 0.25s;
      text-align: center;

      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }

      @media screen and (min-width: 961px) {
        display: none;
      }

      p {
        font-size: 10px;
        margin: 2px auto 0;
        white-space: pre;
      }
    }
  }
}