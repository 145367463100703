.edit {
  display: block;
  width: 100%;

  &__input {
    position: relative;
    input {
      font-size: 16px;
      border-radius: 35px;
      border: 0;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,0.1);
      padding: 15px 24px;
      transition: all .3s ease;
      width: 100%;

      &::placeholder {
        color: #888;
      }

      &:focus {
        box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.2);
        outline: 0;
      }

      &:hover {
        box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.2);
      }
    }
  }

  &__addButton {
    position: absolute !important;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    &__icon {
      color: #4193ae;
    }
  }

  &__chips {
    flex-wrap: wrap;
    margin-top: 15px;

    &__chip {
      background: #d8eaf0 !important;
      margin-top: 10px !important;
    }
  }

  &__btn {
    color: #fff;
    text-align: center;
    background: #4193ae;
    border-radius: 8px;
    margin-top: 24px;
    padding: 20px 5px;
    width: 100%;
    transition: all .3s ease;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.2);
    }
  }
}