.searchbox {
  display: block;
  position: relative;
  width: calc(100% - 80px);

  @media screen and (min-width: 961px) {
    margin-left: auto;
    max-width: 480px;
  }

  input {
    font-size: 16px;
    background: #f6f6f6;
    border-radius: 6px;
    border: 0;
    box-sizing: border-box;
    padding: 18px 20px;
    transition: all .3s ease;
    width: 100%;

    &:focus {
      outline: 0;
    }

    @media screen and (min-width: 961px) {
      background: #fff;
      box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.2);
      border-radius: 35px;
      padding: 24px 40px;

      &:focus {
        box-shadow: 0px 10px 28px 8px rgba(0,0,0,0.2);
        outline: 0;
      }

      &:hover {
        box-shadow: 0px 10px 28px 8px rgba(0,0,0,0.2);
      }
    }

    &::placeholder {
      color: #888;
    }
  }

  &__closeButton {
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;

    @media screen and (min-width: 961px) {
      right: 20px;
    }

    &__icon {
      color: #dfdfdf;
    }
  }
}

.ais-SearchBox-reset {
  display: none;
}